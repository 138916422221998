import { useState } from 'react';
import { useLanguage } from '../contexts/LanguageContext';
import {ImageTranslation} from "../translations/image.ts";

const CompanyStats = () => {
  const [showDevOpsModal, setShowDevOpsModal] = useState(false);
  const [showBrunisModal, setShowBrunisModal] = useState(false);
  const [showReferenceModal, setShowReferenceModal] = useState(false);
  const { translations } = useLanguage();
  const [showDobaiModal, setShowDobaiModal] = useState(false);


  const { language } = useLanguage();
  const picture = ImageTranslation[language];


  return (
    <div id="about" className="bg-white py-5">
      <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-light text-gray-900 mb-5">{translations.about}</h2>
        
        {/* Top Row */}
        <div className="grid grid-cols-12 gap-3">
          {/* Left Image */}
          <div className="cursor-pointer group relative  overflow-hidden rounded-lg col-span-5"> 
            <img
                src="../../assets/Pictures/novobit_Braunschweig.jpg"
                alt="Office View"
                className="w-full h-full object-cover transition-opacity duration-300 group-hover:opacity-0"
            />
            <img
                src={translations.OfficePicture}
                alt="Office View"
                className="absolute inset-0 w-full h-full object-cover opacity-0 group-hover:opacity-100 transition-opacity duration-300"
            />
            
          </div>

          {/* Middle Section - Brunis */}
          <div   className="relative   overflow-hidden rounded-lg col-span-5 cursor-pointer group"
            onClick={() => setShowBrunisModal(true)}  >
            <img 
              src={translations.about_1_1}
              alt="Brunis" 
              className="w-full h-full object-cover transition-opacity duration-300 group-hover:opacity-0"
            />
            <img 
              src={translations.about_1_1} 
              alt="Brunis Alternate" 
              className="absolute inset-0 w-full h-full object-cover opacity-0 group-hover:opacity-100 transition-opacity duration-300"
            />
            <div className="absolute inset-0 bg-black/30 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
              <div className="absolute bottom-6 left-6 text-white">
                <p className="text-sm">{translations.ClickLearnMore}</p>
              </div>
            </div>
          </div>

          {/* Right Section - Stats */}
          <div className="relative overflow-hidden rounded-lg col-span-2">
            <img 
              src={picture.novobit_in_picture} 
              alt="Office" 
              className="w-full h-full object-cover"
            />
          </div>
        </div>

        {/* Bottom Row */}
        <div className="grid  grid-cols-12  gap-3 mt-3">
          {/* DevOps Expertise */}
          <div  className="relative overflow-hidden rounded-lg col-span-2 cursor-pointer group  " onClick={() => setShowDevOpsModal(true)}  >
            <img 
              src={picture.devopsCulture}
              alt="DevOps" 
              className="w-full h-full object-cover transition-opacity duration-300 group-hover:opacity-0"
            />
            <img 
              src={picture.devopsCulture} 
              alt="DevOps Alternate" 
              className="absolute inset-0 w-full h-full object-cover opacity-0 group-hover:opacity-100 transition-opacity duration-300"
            />
            <div className="absolute inset-0 bg-black/30 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
              <div className="absolute bottom-6 left-6 text-white">
                <p className="text-sm">{translations.ClickLearnMore}</p>
              </div>
            </div>
          </div>

          {/* CEO Image with Hover Effect */}
          <div className="relative   overflow-hidden rounded-lg col-span-5 cursor-pointer group
            ">
            <img 
              src="../../assets/Pictures/Hatem_Hamzaoui_novobit.jpg" 
              
              alt="CEO" 
              className="w-full h-full object-cover transition-opacity duration-300 group-hover:opacity-0"
            />
            <div  
                className="absolute inset-0 w-full h-full object-cover opacity-0 group-hover:opacity-100 transition-opacity duration-300"
            >
              <img
                  src={translations.about_3}
                  alt="CEO Alternate"
                  className="absolute inset-0 w-full h-full object-cover opacity-0 group-hover:opacity-100 transition-opacity duration-300"
              />
            </div>
          </div>

          <div className="relative   overflow-hidden rounded-lg col-span-5 cursor-pointer group   ">
            <img
                src="../../assets/Pictures/Braunschweig_Entrance.jpg"

                alt="CEO"
                className="w-full h-full object-cover transition-opacity duration-300 group-hover:opacity-0"
            />
            <div
                className="absolute inset-0 w-full h-full object-cover opacity-0 group-hover:opacity-100 transition-opacity duration-300"
            >
              <img
                  src={translations.Braunschweig_Entrance_Picture}
                  alt="CEO Alternate"
                  className="absolute inset-0 w-full h-full
                        object-top
                   object-cover opacity-0 group-hover:opacity-100 transition-opacity duration-300"
              />
            </div>
          </div>

          

        </div>

        <div className="grid  grid-cols-12 gap-3 mt-3">
           
          <div  className="relative overflow-hidden rounded-lg col-span-5  cursor-pointer group" >
            <img
                src="../../assets/Pictures/novobit_dubai_Internet-City.jpg"
                alt="DevOps"
                className="w-full h-full object-cover transition-opacity duration-300 group-hover:opacity-0"
            />
            <img
                src={translations.about_4}
                alt="DevOps Alternate"
                className="absolute inset-0 w-full h-full object-cover opacity-0 group-hover:opacity-100 transition-opacity duration-300"
            />
          </div>
          

          <div className="relative   overflow-hidden rounded-lg col-span-3 cursor-pointer group ">
            <img
                src="../../assets/Pictures/Team_Dubai.jpg"

                alt="Team dubai"
                className="w-full h-full object-cover    transition-opacity duration-300 group-hover:opacity-0"
            />
            <div
                className="absolute inset-0 w-full h-full object-cover opacity-0 group-hover:opacity-100 transition-opacity duration-300"
            >
              <img
                  src={translations.TeamDubai_Picture}
                  alt="Team dubai"
                  className="absolute inset-0 w-full h-full
                        object-top
                   object-cover opacity-0 group-hover:opacity-100 transition-opacity duration-300"
              />
            </div>
          </div>
 

          <div   className="relative   overflow-hidden rounded-lg col-span-4 cursor-pointer group"  onClick={() => setShowReferenceModal(true)}  >
            <img
                src="../../assets/Pictures/References.jpg"

                alt="Reference"
                className="w-full h-full object-cover transition-opacity duration-300 group-hover:opacity-0"
            />
            <img
                src="../../assets/Pictures/References.jpg"
                alt="Team dubai"
                className="absolute inset-0 w-full h-full object-cover opacity-0 group-hover:opacity-100 transition-opacity duration-300"
            />
            <div className="absolute inset-0 bg-black/30 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
              <div className="absolute bottom-6 left-6 text-white">
                <p className="text-sm">{translations.ClickLearnMore}</p>
              </div>
            </div>
          </div>
          
        </div>

        
      </div>

      {/* DevOps Modal */}
      {showDevOpsModal && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4">
          <div className="bg-white rounded-lg w-full max-w-6xl max-h-[90vh] overflow-y-auto">
            <div className="p-3">
              <div className="flex justify-between items-start  sticky-btn ">
                <h3 className="text-2xl font-light"></h3>
                <button 
                  onClick={() => setShowDevOpsModal(false)}
                  className="bg-gray-400/50 hover:bg-gray-500/50 transition-colors rounded-full p-2"
                >
                  <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
              <div className="aspect-video mb-4">
                <img 
                  src={translations.about_2_2}
                  alt="DevOps Culture" 
                  className="w-full h-full object-cover rounded-lg"
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {showDobaiModal && (
          <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4">
            <div className="bg-white rounded-lg w-full max-w-6xl max-h-[90vh] overflow-y-auto">
              <div className="p-3  ">
                <div className="flex justify-between items-start  sticky-btn ">
                  <h3 className="text-2xl font-light"></h3>
                  <button
                      onClick={() => setShowDobaiModal(false)}
                      className="bg-gray-400/50 hover:bg-gray-500/50 transition-colors rounded-full p-2"
                  >
                    <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
                <div className="aspect-video mb-4">

                  <img
                      src={translations.about_4}
                      alt="CEO Alternate"
                      className="w-full h-full object-cover rounded-lg"
                  /> 
                </div>
              </div>
            </div>
          </div>
      )}
      

      {/* Brunis Modal */}
      {showBrunisModal && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4">
          <div className="bg-white rounded-lg w-full max-w-6xl max-h-[90vh] overflow-y-auto">
            <div className="p-4">
              <div className="flex justify-between items-start  sticky-btn ">
                <h3 className="text-3xl font-light"></h3>
                <button 
                  onClick={() => setShowBrunisModal(false)}
                  className="bg-gray-400/50 hover:bg-gray-500/70 transition-colors rounded-full p-2"
                >
                  <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
              <div className="aspect-video mb-4">
                <img 
                  src={picture.brunisPopUp}
                  alt="Brunis Teams" 
                  className="w-full h-full object-cover rounded-lg"
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {showReferenceModal && (
          <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4">
            <div className="bg-white rounded-lg w-full max-w-6xl max-h-[90vh] overflow-y-auto">
              <div className="p-4">
                <div className="flex justify-between items-start  sticky-btn ">
                  <h3 className="text-3xl font-light"></h3>
                  <button
                      onClick={() => setShowReferenceModal(false)}
                      className="bg-gray-400/50 hover:bg-gray-500/70 transition-colors rounded-full p-2"
                  >
                    <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
                <div className="aspect-video mb-4">
                  <img
                      src={translations.ReferencePicture}
                      
                      alt="Team dubai"
                      className="w-full h-full object-cover rounded-lg"
                  />
                </div>
              </div>
            </div>
          </div>
      )}
    </div>
  );
};

export default CompanyStats;
