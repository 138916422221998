export const translations = {
    de: {
      hatemHamzaoui: 'Hatem Hamzaoui',
      hatemHamzaouiDesc1: 'Geschäftsführer und Gesellschafter der novobit group GmbH\n' +
          'Geschäftsführer der Novobit Partners Sarl\n',
      hatemHamzaouiDesc2: 'Als studierter Informatiker sammelte Hatem im Laufe der Zeit vielfältige\n' +
          'Erfahrungen in der IT-Branche. So entwickelte er nicht nur Software,\n' +
          'sondern auch Software-Unternehmen und deren Zukunft. Im August\n' +
          '2015 gründete Hatem die novobit und baut das Unternehmen seitdem\n' +
          'mit innovativen zukunftsweisenden Themen kontiniuierlich aus.',
      about: 'Über uns',
      software: 'Software Dienstleistungen',
      ai: 'KI-Expertise',
      academy: 'Akademie',
      career: 'Karriere',
      contact: 'Kontakt',
      getInspired: 'Get inspired',
      welcome: 'Willkommen bei novobit, einer IT-Company mit Hauptsitz in Braunschweig und einem zweiten Standort in Tunis.',
      news: 'NOVOBIT AKTUELL',
      PodcastbeiNIEDERSACHSEN:'Podcast bei Niedersachsen.next',
      NewsletterDescPicture:"„Newsletter“",
      newsLetterMagazine: 'Zum IHK-Magazin',
      GERMANEMIRATES:'Vortrag beim German Emirates Club, Dubai'  ,
      DOAG_Tourbus:''  ,
      DOAG_Tourbus_picture:'../../assets/Pictures/DOAG_Tourbus.jpg'  ,
      OfficePicture: '../../assets/_Mouse-over/Braunschweig_Dachterrasse.png',
      DOAG_Tourbus_picture_hover:'../../assets/_Mouse-over/DOAG_Tourbus_de.png'  ,
      Braunschweig_Entrance_Picture: '../../assets/_Mouse-over/Braunschweig_Eingang.png',
      TeamDubai_Picture: '../../assets/_Mouse-over/Team_in-Dubai_ger - Copy.png',
      ReferencePicture: '../../assets/_Mouse-over/Referenzen_de.jpg'  ,
      pdfUrlNewsLetter: '/newsletter_25_1.pdf',
      experience: 'Wir verbinden 30 Jahre Erfahrung in Software-Entwicklung mit zukunftsweisendem Know-how im Bereich Künstliche Intelligenz.',
      efficiency: 'Auf diese Weise entwickeln wir nicht nur Software, sondern vor allem auch Menschen und Unternehmen, Prozesse und Produkte, Ideen und Visionen und vor allem auch etwas ganz Entscheidendes – Effizienz.',
      careerBraunschweig: 'Karriere in Braunschweig',
      careerTunis: 'Karriere in Tunis',
      niceViews: 'Schöne Aussichten',
      jobsBraunschweig: 'JOBS IN BRAUNSCHWEIG',
      jobsTunis: 'JOBS IN TUNIS',
      softwareDev: 'SOFTWARE-ENTWICKLER (M/W/D)',
      fullstack: 'FULL-STACK-ENTWICKLER JAVA (M/W/D)',
      initiative: 'INITIATIVBEWERBUNG (M/W/D)',
      forStudents: 'AUCH FÜR STUDIENABBRECHER',
      about_1_1: "../../assets/Pictures/Brunis_Braunschweig_und_Tunis_novobit.jpg",
      about_1_2: "../../assets/Pictures/Brunis_PopUp_d.png" ,
      about_2_1: "../../assets/Pictures/DevOps-Kultur_novobit.png" ,
      about_2_2: "../../assets/_Pop-ups/novobit_Historie_ge.png",
      about_3: "../../assets/_Mouse-over/Hatem_Hamzaoui_Managing_Director.png",
      about_4: "../../assets/_Mouse-over/novobit_dubai_Vertrieb.png",
      softwareservices_1_1: "../../assets/Pictures/Portfolio_novobit_Software-Entwicklung.jpg",
      softwareservices_1_2: "../../assets/_Pop-ups/DevOps_Leistungen.png",
      lifeCycle_1_2: "../../assets/_Pop-ups/novobit_Projektablauf.png",
      softwareservices_2_1: "../../assets/Pictures/Software_Entwicklung_Process_User_Story_Life_Cycle.jpg",
      softwareservices_2_2: "../../assets/_Pop-ups/KI_Expertise_novobit_Beratung.png",
      softwareservices_3: "../../assets/_Mouse-over/Ingo_Ruebel_Software-Projekte_1.png",
      softwareservices_4: "../../assets/_Mouse-over/Nada_Hamzaoui_Ansprechpartnerin_Tunis-ge.png",
      softwareservices_5: "../../assets/_Pop-ups/Planungssoftware_serielle_Fertigung.png",
      softwareservices_6: "../../assets/_Pop-ups/Planungssoftware_Reporting_Tool_ge.png",
      softwareservices_7: "../../assets/_Mouse-over/Informationssicherheit_novobit_ge.png",
      downloadPdf: "PDF herunterladen",
      softwareservices_8: "../../assets/_Pop-ups/digitale_Signage-Systeme.png",
      ki_expert_1_1: "../../assets/Pictures/AI-Lab_novobit_Know-how.jpg",
      ki_expert_1_2: "../../assets/_Pop-ups/KI_Expertise_novobit_Beratung.png",
      ki_expert_2: "../../assets/_Mouse-over/novobit_KI-Lab_Gruendungsteam.png",
      ki_expert_3: "../../assets/_Pop-ups/KI_Expertise_novobit_smartmaps.png",
      ki_expert_4: "../../assets/_Mouse-over/Ramzi_Guetari_KI-Programmdirektor.png",
      ki_expert_5: "../../assets/_Pop-ups/KI_Expertise_novobit_smartfake.png",
      ki_expert_6_1: "../../assets/Pictures/Visionen_novobit_ge_new.jpg",
      ki_expert_6_2: "../../assets/_Pop-ups/KI_Expertise_ogi.png",
      ki_expert_7: "../../assets/_Pop-ups/KI_Produktentwicklung_devally.png",
      academy_1: "../../assets/Pictures/Akademie_KI-Entwickler.jpg",
      academy_2: "../../assets/_Pop-ups/Akademie_novobit.jpg",
      career_1_1: "../../assets/Pictures/Braunschweig_Panorama_Aussicht.jpg",
      career_1_1_2: "../../assets/_Pop-ups/novobit_Benefits_Braunschweig.png",
      career_1_2: "../../assets/_Pop-ups/novobit_Benefits_Tunis.png",
      career_2: "../../assets/_Pop-ups/novobit_Bewerbungsprozess_ge.png",
      career_3_1: "../../assets/Pictures/novobit_Jobs_Braunschweig.png",
      career_3_2: "../../assets/_Pop-ups/novobit_Jobs_in_Braunschweig_Liste-ge.png",
      career_4_1: "../../assets/Pictures/Tunis_Panorama_Aussicht.jpg",
      career_4_2: "../../assets/_Pop-ups/novobit_Benefits_Tunis.png",
      career_5: "../../assets/_Pop-ups/novobit_Bewerbungsprozess_Tunis_ge.png",
      career_6_1: "../../assets/Pictures/novobit_Jobs_Tunis.png",
      career_6_2: "../../assets/_Pop-ups/novobit_Jobs_in_Tunis_Liste_ge.png",
      career_PDF: '/NG_FO_Stellenausschreibung_DEU.pdf',
      Softwareentwickler: 'Softwareentwickler (m/f/d)',
      contact_1: "../../assets/_Pop-ups/novobit_Contacts_ger.png",
      contact_1_2: "../../assets/Pictures/novobit_Kontakt_ge.jpg",
      conatct_2: "../../assets/_Pop-ups/novobit_Kontakte.png",
      ClickLearnMore: "Erfahre mehr"
    },
    en: {
      hatemHamzaoui: 'Hatem Hamzaoui',
      hatemHamzaouiDesc1: 'Managing Director and shareholder of novobit group GmbH\n' +
          'Managing Director of Novobit Partners Sarl',
      hatemHamzaouiDesc2: 'As a graduate computer scientist, Hatem has gained a wide range\n' +
          'of experience in the IT industry over the years. He not only developed\n' +
          'software, but also software companies and their future. Hatem\n' +
          'founded novobit in August 2015 and has been continuously expanding\n' +
          'the ycompany with innovative, forward-looking topics ever since',
      about: 'About us',
      software: 'Software Services',
      ai: 'AI Expertise',
      academy: 'Academy',
      career: 'Career',
      contact: 'Contact',
      getInspired: 'Get inspired',
      welcome: 'Welcome to novobit, an IT company headquartered in Braunschweig with a second location in Tunis.',
      news: 'NOVOBIT NEWS',
      PodcastbeiNIEDERSACHSEN:"Podcast on Niedersachsen.next.",
      NewsletterDescPicture:"Newsletter 01|25",
      newsLetterMagazine: 'To the IHK magazine',
      GERMANEMIRATES:'Lecture at the German Emirates Club, Dubai'  ,
      DOAG_Tourbus:''  ,
      DOAG_Tourbus_picture:'../../assets/Pictures/DOAG_Tourbus.jpg'  ,
      OfficePicture: '../../assets/_Mouse-over/Braunschweig_roof_terrasse.png',
      DOAG_Tourbus_picture_hover:'../../assets/_Mouse-over/DOAG_Tourbus_en.png'  , 
      Braunschweig_Entrance_Picture: '../../assets/_Mouse-over/Braunschweig_Entrance.png',
      TeamDubai_Picture: '../../assets/_Mouse-over/Team_in-Dubai_en.png',
      ReferencePicture: '../../assets/_Mouse-over/References_en.jpg'  ,
      pdfUrlNewsLetter: '/newsletter_25_1_en.pdf',
      experience: 'We combine 30 years of software development experience with forward-thinking expertise in artificial intelligence.',
      efficiency: 'In this way, we develop not only software, but above all people and companies, processes and products, ideas and visions, and most importantly, something crucial - efficiency.',
      careerBraunschweig: 'Career in Braunschweig',
      careerTunis: 'Career in Tunis',
      niceViews: 'Nice Views',
      jobsBraunschweig: 'JOBS IN BRAUNSCHWEIG',
      jobsTunis: 'JOBS IN TUNIS',
      softwareDev: 'SOFTWARE DEVELOPER (M/F/D)',
      fullstack: 'FULL-STACK DEVELOPER JAVA (M/F/D)',
      initiative: 'INITIATIVE APPLICATION (M/F/D)',
      forStudents: 'ALSO FOR UNIVERSITY DROPOUTS',
      about_1_1: "../../assets/Pictures/Brunis_Braunschweig_and_Tunis_novobit.jpg",
      about_1_2: "../../assets/Pictures/Brunis_PopUp_d.png" ,
      about_2_1: "../../assets/Pictures/DevOps-Kultur_novobit.png" ,
      about_2_2: "../../assets/_Pop-ups/novobit_History_en.png",
      about_3: "../../assets/_Mouse-over/Hatem_Hamzaoui_Geschaeftsfuehrer.png",
      about_4: "../../assets/_Mouse-over/novobit_dubai_Sales.png",
      softwareservices_1_1: "../../assets/Pictures/Portfolio_novobit_Software-Entwicklung_EN.jpg",
      softwareservices_1_2: "../../assets/_Pop-ups/DevOps_Leistungen_EN.png",
      lifeCycle_1_2: "../../assets/_Pop-ups/novobit_Projektablauf_en.png",
      softwareservices_2_1: "../../assets/Pictures/Software_Entwicklung_Process_User_Story_Life_Cycle.jpg",
      softwareservices_2_2: "../../assets/_Pop-ups/AI_Expertise_novobit_Consulting.png",
      softwareservices_3: "../../assets/_Mouse-over/Ingo_Ruebel_Software-Projekte_en.png",
      softwareservices_4: "../../assets/_Mouse-over/Nada_Hamzaoui_Software_Projects_2.png",
      softwareservices_5: "../../assets/_Pop-ups/Planning_software_serial_production.png",
      softwareservices_6: "../../assets/_Pop-ups/Planungssoftware_Reporting_Tool_en.png",
      softwareservices_7: "../../assets/_Mouse-over/Information_Secutity_novobit_en.png",
      downloadPdf: "Download PDF",
      softwareservices_8: "../../assets/_Pop-ups/digital_Signage-Systems.png",
      ki_expert_1_1: "../../assets/Pictures/AI-Lab_novobit_Know_how.jpg",
      ki_expert_1_2: "../../assets/_Pop-ups/KI_Expertise_novobit_Beratung.png",
      ki_expert_2: "../../assets/_Mouse-over/novobit_AI_Lab_Founding_Team.png",
      ki_expert_3: "../../assets/_Pop-ups/AI_Expertise_novobit_smartmaps.png",
      ki_expert_4: "../../assets/_Mouse-over/Ramzi_Guetari_AI_Program_Director.png",
      ki_expert_5: "../../assets/_Pop-ups/KI_Expertise_novobit_smartfake_en.png",     
      
      ki_expert_6_1: "../../assets/Pictures/Visions_novobit_en.jpg",
      ki_expert_6_2: "../../assets/_Pop-ups/AI_Expertise_novobit.png",
      ki_expert_7: "../../assets/_Pop-ups/AI_Product_development_devally.png",
      academy_1: "../../assets/Pictures/Academy_AI_Developer_novobit.jpg",
      academy_2: "../../assets/_Pop-ups/Academy_novobit.jpg",
      career_1_1: "../../assets/Pictures/Braunschweig_Panorama_Prospects.jpg",
      career_1_1_2: "../../assets/_Pop-ups/novobit_Benefits_employees_Braunschweig.png",
      career_1_2: "../../assets/_Pop-ups/novobit_Benefits_employees_Tunis_en.png",
      career_2: "../../assets/_Pop-ups/novobit_Bewerbungsprozess_en.png",
      career_3_1: "../../assets/Pictures/novobit_Jobs_Braunschweig.png",
      career_3_2: "../../assets/_Pop-ups/novobit_Jobs_in_Brunswick_List_en.png",
      career_4_1: "../../assets/Pictures/Tunis_Panorama_Aussicht_en.jpg",
      career_4_2: "../../assets/_Pop-ups/novobit_Benefits_Tunis.png",
      career_5: "../../assets/_Pop-ups/novobit_Application_Process_Tunis_eng.png",
      career_6_1: "../../assets/Pictures/novobit_Jobs_Tunis.png",
      career_6_2: "../../assets/_Pop-ups/novobit_Jobs_in_Tunis_List_en.png",
      career_PDF: '/NG_FO_Stellenausschreibung_Softwareentwickler_ENG.pdf',
      Softwareentwickler: 'Software Developer (m/f/d)',
      contact_1: "../../assets/_Pop-ups/novobit_Contacts_en.png",
      contact_1_2: "../../assets/Pictures/Contact_novobit.jpg",
      conatct_2: "../../assets/_Pop-ups/novobit_Kontakte.png"  ,
      ClickLearnMore: "Click to learn more"
    },
    ar: {
      hatemHamzaoui: 'Hatem Hamzaoui',
      hatemHamzaouiDesc1: 'Managing Director and shareholder of novobit group GmbH\n' +
          'Managing Director of Novobit Partners Sarl',
      hatemHamzaouiDesc2: 'As a graduate computer scientist, Hatem has gained a wide range\n' +
          'of experience in the IT industry over the years. He not only developed\n' +
          'software, but also software companies and their future. Hatem\n' +
          'founded novobit in August 2015 and has been continuously expanding\n' +
          'the ycompany with innovative, forward-looking topics ever since',
      about: 'من نحن',
      software: 'خدمات البرمجيات',
      ai: 'خبرة الذكاء الاصطناعي',
      academy: 'الأكاديمية',
      career: 'الوظائف',
      contact: 'اتصل بنا',
      getInspired: 'كن ملهماً',
      welcome: 'مرحباً بكم في نوفوبت، شركة تكنولوجيا المعلومات التي يقع مقرها الرئيسي في براونشفايغ ولديها موقع ثانٍ في تونس.',
      news: 'أخبار نوفوبت',
      PodcastbeiNIEDERSACHSEN:'بودكاست في نيدرساكسن',
      NewsletterDescPicture:"النشرة الإخبارية",
      newsLetterMagazine: 'Zum IHK-Magazin',
      GERMANEMIRATES:'محاضرة في النادي الألماني الإماراتي، دبي'  ,
      DOAG_Tourbus:''  ,
      DOAG_Tourbus_picture:'../../assets/Pictures/DOAG_Tourbus.jpg'  ,
      OfficePicture: '../../assets/_Mouse-over/Braunschweig_Dachterrasse.png',
      DOAG_Tourbus_picture_hover:'../../assets/_Mouse-over/DOAG_Tourbus_de.png'  ,
      Braunschweig_Entrance_Picture: '../../assets/_Mouse-over/Braunschweig_Eingang.png',
      TeamDubai_Picture: '../../assets/_Mouse-over/Team_in-Dubai_ger.png',
      ReferencePicture: '../../assets/_Mouse-over/Referenzen_de.jpg'  ,
      pdfUrlNewsLetter: '/newsletter_25_1.pdf',
      experience: 'نجمع بين 30 عاماً من الخبرة في تطوير البرمجيات مع خبرة مستقبلية في مجال الذكاء الاصطناعي.',
      efficiency: 'بهذه الطريقة، نحن نطور ليس فقط البرمجيات، ولكن قبل كل شيء الأشخاص والشركات والعمليات والمنتجات والأفكار والرؤى، والأهم من ذلك، شيئاً حاسماً - الكفاءة.',
      careerBraunschweig: 'الوظائف في براونشفايغ',
      careerTunis: 'الوظائف في تونس',
      niceViews: 'مناظر جميلة',
      jobsBraunschweig: 'الوظائف في براونشفايغ',
      jobsTunis: 'الوظائف في تونس',
      softwareDev: 'مطور برمجيات (ذكر/أنثى)',
      fullstack: 'مطور فول ستاك جافا (ذكر/أنثى)',
      initiative: 'تقديم مبادرة (ذكر/أنثى)',
      forStudents: 'متاح أيضاً للطلاب المتوقفين عن الدراسة',
      about_1_1: "../../assets/Pictures/Brunis_Braunschweig_and_Tunis_novobit.jpg",
      about_1_2: "../../assets/Pictures/Brunis_PopUp_d.png" ,
      about_2_1: "../../assets/Pictures/DevOps-Kultur_novobit.png" ,
      about_2_2: "../../assets/_Pop-ups/Historie_novobit.png",
      about_3: "../../assets/_Mouse-over/Hatem_Hamzaoui_Managing_Director.png",
      about_4: "../../assets/_Mouse-over/novobit_dubai_Vertrieb.png",
      softwareservices_1_1: "../../assets/Pictures/Portfolio_novobit_Software-Entwicklung.jpg",
      softwareservices_1_2: "../../assets/_Pop-ups/DevOps_Leistungen.png",
      lifeCycle_1_2: "../../assets/_Pop-ups/novobit_Projektablauf.png",
      softwareservices_2_1: "../../assets/Pictures/Software_Entwicklung_Process_User_Story_Life_Cycle.jpg",
      softwareservices_2_2: "../../assets/_Pop-ups/KI_Expertise_novobit_Beratung.png",
      softwareservices_3: "../../assets/_Mouse-over/Ingo_Ruebel_Software-Projekte_1.png",
      softwareservices_4: "../../assets/_Mouse-over/Nada_Hamzaoui_Ansprechpartnerin_Tunis.png",
      softwareservices_5: "../../assets/_Pop-ups/Planungssoftware_serielle_Fertigung.png",
      softwareservices_6: "../../assets/_Pop-ups/Planungssoftware_Reporting_Tool_en.png",
      softwareservices_7: "../../assets/_Mouse-over/Informationssicherheit_novobit.png",
      downloadPdf: "PDF herunterladen",
      softwareservices_8: "../../assets/_Pop-ups/digitale_Signage-Systeme.png",
      ki_expert_1_1: "../../assets/Pictures/AI-Lab_novobit_Know-how.jpg",
      ki_expert_1_2: "../../assets/_Pop-ups/KI_Expertise_novobit_Beratung.png",
      ki_expert_2: "../../assets/_Mouse-over/novobit_KI-Lab_Gruendungsteam.png",
      ki_expert_3: "../../assets/_Pop-ups/KI_Expertise_novobit_smartmaps.png",
      ki_expert_4: "../../assets/_Mouse-over/Ramzi_Guetari_KI-Programmdirektor.png",
      ki_expert_5: "../../assets/_Pop-ups/KI_Expertise_novobit_smartfake.png",
      ki_expert_6_1: "../../assets/Pictures/Visions_novobit.jpg",
      ki_expert_6_2: "../../assets/_Pop-ups/KI_Expertise_ogi.png",
      ki_expert_7: "../../assets/_Pop-ups/novobit_Produktentwicklung_devally.png",
      academy_1: "../../assets/Pictures/Akademie_KI-Entwickler.jpg",
      academy_2: "../../assets/_Pop-ups/Akademie_novobit.jpg",
      career_1_1: "../../assets/Pictures/Braunschweig_Panorama_Aussicht.jpg",
      career_1_1_2: "../../assets/_Pop-ups/novobit_Benefits_Braunschweig.png",
      career_1_2: "../../assets/_Pop-ups/novobit_Benefits_Tunis.png",
      career_2: "../../assets/_Pop-ups/novobit_Bewerbungsprozess.png",
      career_3_1: "../../assets/Pictures/novobit_Jobs_Braunschweig.png",
      career_3_2: "../../assets/_Pop-ups/novobit_Jobs_in_Brunswick_List_en.png",
      career_4_1: "../../assets/Pictures/Tunis_Panorama_Aussicht.jpg",
      career_4_2: "../../assets/_Pop-ups/novobit_Benefits_Tunis.png",
      career_5: "../../assets/_Pop-ups/novobit_Bewerbungsprozess_Tunis.png",
      career_6_1: "../../assets/Pictures/novobit_Jobs_Tunis.png",
      career_6_2: "../../assets/_Pop-ups/novobit_Jobs_in_Tunis_Liste.png",
      career_PDF: '/NG_FO_Stellenausschreibung_DEU.pdf',
      Softwareentwickler: 'Softwareentwickler (m/f/d)',
      contact_1: "../../assets/_Pop-ups/novobit_Contacts.png",
      contact_1_2: "../../assets/Pictures/novobit_Kontakt_ge.jpg",
      conatct_2: "../../assets/_Pop-ups/novobit_Kontakte.png" ,
      ClickLearnMore: "انقر لمعرفة المزيد"
    }
  } as const;
