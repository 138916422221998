export const supervisoryTranslations = {
  de: {
    title: 'Aufsichtsbehörde',
    description: 'Bei Problemen mit dem Datenschutz können Sie sich jederzeit zudem an die in Niedersachsen zuständige\n' +
        'Aufsichtsbehörde wenden:',
    authority: {
      name: 'Die Landesbeauftragte für den Datenschutz Niedersachsen ',
      address: 'Prinzenstraße 5',
      city: '30159 Hannover',
      phone: 'Telefon: +49 511 120-4500',
      fax: 'Telefax: +49 511 120-4599,',
      email: 'E-Mail: poststelle@lfd.niedersachsen.de'
    }
  },
  en: {
    title: 'Supervisory Authority',
    description: 'If you have problems with data protection, you can also contact the responsible supervisory authority in Lower Saxony at any time:',
    authority: {
      name: 'The State Commissioner for Data Protection Lower Saxony',
      address: 'Prinzenstrasse 5',
      city: '30159 Hannover',
      phone: 'Phone: +49 511 120-4500',
      fax: 'Fax: +49 511 120-4599',
      email: 'Email: poststelle@lfd.niedersachsen.de'
    }
  },
  ar: {
    title: 'السلطة الإشرافية',
    description: 'إذا كان لديك مشاكل مع حماية البيانات، يمكنك أيضاً الاتصال بالسلطة الإشرافية المسؤولة في ساكسونيا السفلى في أي وقت:',
    authority: {
      name: 'مفوض الدولة لحماية البيانات في ساكسونيا السفلى',
      address: 'برينزنشتراسه 5',
      city: '30159 هانوفر',
      phone: 'هاتف: +49 511 120-4500',
      fax: 'فاكس: +49 511 120-4599',
      email: 'البريد الإلكتروني: poststelle@lfd.niedersachsen.de'
    }
  }
};
