import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useLanguage } from '../../contexts/LanguageContext';

const PageNavbar = () => {
  const { language, setLanguage } = useLanguage();


  useEffect(() => {
    document.documentElement.dir = language === 'ar' ? 'rtl' : 'ltr';
  }, [language]);

  return (
    <nav className="fixed w-full z-50 bg-white shadow-md">
      <div className="max-w-[1920px] mx-auto px-6 lg:px-12">
      <div className="flex justify-between items-center h-20">
          {/* Logo */}
          <div className="flex-shrink-0">
            <Link to="/">
              <img 
                src="../../assets/Logo_png/novobit_Logo_red_black_rgb.png"
                alt="Novobit" 
                className="h-20" />
            </Link>
          </div>

          {/* Right Side Menu */}
          <div className="flex items-center space-x-4">
            {/* Language Selector */}
            <div className="flex items-center space-x-2 text-sm">
              <button 
                onClick={() => setLanguage('de')}
                className={`${language === 'de' ? 'text-red-600' : 'text-gray-700'} hover:text-red-600 transition-colors`}
              >
                de
              </button>
              <span className="text-gray-300">|</span>
              <button 
                onClick={() => setLanguage('en')}
                className={`${language === 'en' ? 'text-red-600' : 'text-gray-700'} hover:text-red-600 transition-colors`}
              >
                en
              </button>
              {/*<span className="text-gray-300">|</span>*/}
              {/*<button */}
              {/*  onClick={() => setLanguage('ar')}*/}
              {/*  className={`${language === 'ar' ? 'text-red-600' : 'text-gray-700'} hover:text-red-600 transition-colors`}*/}
              {/*>*/}
              {/*  ar*/}
              {/*</button>*/}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default PageNavbar;
