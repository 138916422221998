export const securityTranslations = {
  de: {
    title: 'Datensicherheit',
    description: 'Ihre Daten werden selbstverständlich mit den üblichen technischen und organisatorischen Maßnahmen vor\n' +
        'dem Zugriff durch Unbefugte geschützt. Hierbei kommen Virenscanner, Firewall und passwortgeschützte\n' +
        'Rechner zum Einsatz. Im Falle von Datendiebstahl verpflichten wir uns, Sie umgehend umfassend und\n' +
        'transparent zu informieren.'
  },
  en: {
    title: 'Data Security',
    description: 'Your data is, of course, protected from unauthorized access with standard technical and organizational measures. This includes virus scanners, firewalls, and password-protected computers. In the event of data theft, we commit to informing you immediately, comprehensively, and transparently.'
  },
  ar: {
    title: 'أمن البيانات',
    description: 'بالطبع، يتم حماية بياناتك من الوصول غير المصرح به باستخدام التدابير التقنية والتنظيمية القياسية. وهذا يشمل برامج مكافحة الفيروسات وجدران الحماية وأجهزة الكمبيوتر المحمية بكلمة مرور. في حالة سرقة البيانات، نلتزم بإبلاغك على الفور بشكل شامل وشفاف.'
  }
};
