export const whistleblowerTranslations = {
    de: {
      title: 'Hinweisgebersystem',
      introduction: 'Die novobit group GmbH legt höchsten Wert auf die Einhaltung von Gesetzen, Vorschriften und internen\n' +
          'Richtlinien. Nur durch rechtmäßiges und ethisches Verhalten können wir die Sicherheit unseres\n' +
          'Unternehmens, unserer Mitarbeiter und unserer Geschäftspartner gewährleisten. Unser\n' +
          'Hinweisgebersystem erfasst konkrete Meldungen von Personen, die während ihrer beruflichen Tätigkeit\n' +
          'Kenntnisse über Verstöße gegen gesetzliche Vorschriften oder andere bindende Regelungen erlangt haben\n' +
          'und diese melden möchten. ',
      howToReport: {
        title: 'So geben Sie einen Hinweis',
        content: 'Es erfordert die Aufmerksamkeit und Bereitschaft aller Beteiligten, etwaige Regelverstöße innerhalb\n' +
            'unseres Unternehmens zu melden. Falls konkrete Informationen vorliegen, können diese vertraulich über\n' +
            'das Hinweisgebersystem gemeldet werden. Dies betrifft insbesondere Verstöße gegen unseren\n' +
            'Verhaltenskodex, das Kartellrecht, Korruption, Diebstahl, Diskriminierung oder Mobbing. Um den\n' +
            'bestmöglichen Schutz für Hinweisgeber und Betroffene sicherzustellen, haben wir einen externen\n' +
            'Ombudsmann, Rechtsanwalt Nils Lippert von der Kanzlei am Weinberg , beauftragt. Bei Verdachtsfällen\n' +
            'nach dem HinSchG können Sie sich vertrauensvoll an ihn wenden. Neben unseren Mitarbeitern können\n' +
            'selbstverständlich auch Geschäftspartner, Kunden oder andere Dritte Hinweise geben. '
      },
      howToFormulate: {
        title: 'So formulieren Sie einen Hinweis',
        introduction: 'Es ist von großer Bedeutung, dass der Hinweis möglichst präzise formuliert ist und auch von Personen, die\n' +
            'nicht direkt involviert sind, verstanden werden kann. Es wäre hilfreich, wenn Sie bei der Meldung folgende\n' +
            'Fragen beantworten könnten:',
        questions: [
          'Betroffene Person(en): Wer ist von dem Vorfall betroffen? Wer ist daran beteiligt?',
          'Geschehenes Ereignis: Was ist genau passiert? Bitte beschreiben Sie den Vorfall detailliert.',
          'Zeitpunkt des Vorfalls: Wann hat sich das Ereignis zugetragen?',
          'Häufigkeit: Wie oft ist der Vorfall eingetreten?',
          'Ort des Geschehens: Wo hat sich der Vorfall ereignet?'
        ]
      }        ,
      btnHinweisgeben : 'Hinweis geben !'
    },
    en: {
      title: 'Whistleblower System',
      introduction: 'Novobit Group GmbH places the highest priority on compliance with laws, regulations, and internal policies. Only through lawful and ethical conduct can we ensure the safety of our company, employees, and business partners. Our whistleblowing system is designed to receive specific reports from individuals who have gained knowledge of violations of legal provisions or other binding regulations during their professional activities and wish to report them.',
      howToReport: {
        title: 'How to Submit a Report',
        content: 'It requires the vigilance and willingness of all involved to report potential rule violations within our company. If you have specific information, you can report it confidentially via the whistleblowing system. This particularly includes violations of our Code of Conduct, antitrust laws, corruption, theft, discrimination, or bullying. To provide the best possible protection for whistleblowers and affected individuals, we have engaged an external ombudsman, Attorney Nils Lippert from the Kanzlei am Weinberg law firm. If you suspect a case covered under the Whistleblower Protection Act (HinSchG), you can confidently reach out to him. In addition to our employees, business partners, customers, or other third parties are also welcome to submit reports.'
      },
      howToFormulate: {
        title: 'How to Formulate a Report',
        introduction: 'It is essential that the report is as precise as possible and can be understood by individuals who are not\n' +
            'directly involved. To assist in the process, we kindly ask you to address the following questions in your\n' +
            'report:',
        questions: [
          'Affected Person(s): Who is affected by the incident? Who is involved?',
          'Event Description: What exactly happened? Please describe the incident in detail.',
          'Time of Incident: When did the event occur?',
          'Frequency: How often has the incident occurred?',
          'Location: Where did the incident take place?'
        ]
      }   ,
      btnHinweisgeben : 'Give us a hint !'
    },
    ar: {
      title: 'نظام الإبلاغ عن المخالفات',
      introduction: 'تولي شركة نوفوبت جروب أهمية قصوى للامتثال للقوانين واللوائح والإرشادات الداخلية. فقط من خلال السلوك القانوني والأخلاقي يمكننا ضمان أمن شركتنا وموظفينا وشركائنا التجاريين. يسجل نظام الإبلاغ عن المخالفات لدينا تقارير محددة من الأفراد الذين اكتسبوا معرفة بانتهاكات اللوائح القانونية أو القواعد الملزمة الأخرى أثناء أنشطتهم المهنية ويرغبون في الإبلاغ عنها.',
      howToReport: {
        title: 'كيفية تقديم بلاغ',
        content: 'يتطلب الأمر انتباه واستعداد جميع الأطراف المعنية للإبلاغ عن أي انتهاكات للقواعد داخل شركتنا. إذا توفرت معلومات محددة، يمكن الإبلاغ عنها بسرية من خلال نظام الإبلاغ عن المخالفات. يتعلق هذا بشكل خاص بانتهاكات مدونة قواعد السلوك لدينا، وقانون مكافحة الاحتكار، والفساد، والسرقة، والتمييز، أو التنمر. لضمان أفضل حماية ممكنة للمبلغين والأطراف المتضررة، قمنا بتعيين أمين مظالم خارجي، المحامي نيلز ليبرت من كانزلي آم فاينبرج. في حالات الاشتباه في وجود انتهاكات بموجب قانون حماية المبلغين عن المخالفات، يمكنك الاتصال به بسرية. بالإضافة إلى موظفينا، يمكن للشركاء التجاريين والعملاء أو الأطراف الثالثة الأخرى أيضاً تقديم التقارير.'
      },
      howToFormulate: {
        title: 'كيفية صياغة البلاغ',
        introduction: 'من الضروري أن يكون التقرير دقيقًا قدر الإمكان ويمكن فهمه من قبل الأشخاص الذين ليسوا معنيين مباشرة. للمساعدة في هذه العملية، نرجو منك الإجابة على الأسئلة التالية في تقريرك:',
        questions: [
          'الشخص (الأشخاص) المتضرر: من المتضرر من الحادث؟ من المتورط؟',
          'وصف الحدث: ماذا حدث بالضبط؟ يرجى وصف الحادث بالتفصيل.',
          'وقت الحادث: متى وقع الحدث؟',
          'التكرار: كم مرة وقع الحادث؟',
          'الموقع: أين وقع الحادث؟'
        ]
      }  ,
      btnHinweisgeben : 'أعطنا تلميحا !'
    }
  };
