export const youtubeTranslations = {
  de: {
    title: 'YouTube',
    description: 'Für die Integration und Darstellung von Videoinhalten nutzt unsere Website Plug-ins von YouTube. Anbieter\n' +
        'des Videoportals ist die YouTube, LLC, 901 Cherry Ave., San Bruno, CA 94066, USA. Bei Aufruf einer Seite mit\n' +
        'integriertem YouTube-Plug-in wird eine Verbindung zu den Servern von YouTube hergestellt. YouTube erfährt\n' +
        'hierdurch, welche unserer Seiten Sie aufgerufen haben. YouTube kann Ihr Surfverhalten direkt Ihrem\n' +
        'persönlichen Profil zuordnen, sollten Sie in Ihrem YouTube-Konto eingeloggt sein. Durch vorheriges\n' +
        'Ausloggen haben Sie die Möglichkeit, dies zu unterbinden. Die Nutzung von YouTube erfolgt im Interesse\n' +
        'einer ansprechenden Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes Interesse im Sinne\n' +
        'von Art. 6 Abs. 1 lit. f DSG-VO dar. Einzelheiten zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerklärung von YouTube unter: ',
    privacy: 'Einzelheiten zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerklärung von YouTube unter: https://www.google.de/intl/de/policies/privacy'
  },
  en: {
    title: 'YouTube',
    description: 'For the integration and display of video content, our website uses plug-ins from YouTube. The provider of the\n' +
        'video portal is YouTube, LLC, 901 Cherry Ave, San Bruno, CA 94066, USA. When a page with an integrated\n' +
        'YouTube plug-in is called up, a connection to the YouTube servers is established. This tells YouTube which of\n' +
        'our pages you have accessed. YouTube can assign your surfing behaviour directly to your personal profile if\n' +
        'you are logged into your YouTube account. You can prevent this by logging out first. YouTube is used in the\n' +
        'interest of an appealing presentation of our online offers. This represents a legitimate interest within the\n' +
        'meaning of Article 6, paragraph 1, lit. f of the GDPR. Details on the handling of user data can be found in YouTube\'s privacy policy at',
    privacy: 'Details about the handling of user data can be found in YouTube\'s privacy policy at:'
  },
  ar: {
    title: 'يوتيوب',
    description: 'لدمج وعرض محتوى الفيديو، يستخدم موقعنا الإلكتروني إضافات من يوتيوب. مزود بوابة الفيديو هو يوتيوب، LLC، 901 شارع شيري، سان برونو، كاليفورنيا 94066، الولايات المتحدة الأمريكية. عند فتح صفحة تحتوي على إضافة يوتيوب مدمجة، يتم إنشاء اتصال مع خوادم يوتيوب. وهذا يخبر يوتيوب بأي من صفحاتنا التي قمت بالوصول إليها. يمكن ليوتيوب ربط سلوك التصفح الخاص بك مباشرةً مع ملفك الشخصي إذا كنت قد قمت بتسجيل الدخول إلى حسابك على يوتيوب. يمكنك منع ذلك بتسجيل الخروج أولاً. يُستخدم يوتيوب في مصلحة تقديم عروضنا الإلكترونية بشكل جذاب. وهذا يمثل مصلحة مشروعة وفقًا للمادة 6، الفقرة 1، البند (و) من اللائحة العامة لحماية البيانات (GDPR).\n' +
        '\n' +
        'يمكن العثور على تفاصيل حول كيفية التعامل مع بيانات المستخدم في سياسة الخصوصية الخاصة بـ يوتيوب على',
    privacy: 'يمكن العثور على تفاصيل حول التعامل مع بيانات المستخدم في سياسة خصوصية يوتيوب على: https://www.google.de/intl/ar/policies/privacy'
  }
};
