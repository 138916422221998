export const socialMediaTranslations = {
  de: {
    facebook: {
      title: 'Datenschutzerklärung für Facebook',
      description: 'Unsere Website verwendet Funktionen von Facebook Inc., 1601 S. California Ave, Palo Alto, CA 94304, USA. ' +
          'Bei Aufruf unserer Seiten mit Facebook-Plug-ins wird eine Verbindung zwischen Ihrem Browser und den ' +
          'Servern von Facebook hergestellt. Dabei schon werden bereits Daten an Facebook übertragen. Besitzen Sie ' +
          'einen Facebook-Account, können diese Daten damit sogar verknüpft werden. Wenn Sie keine Zuordnung ' +
          'dieser Daten zu Ihrem Facebook-Account wünschen, loggen Sie sich bitte vor dem Besuch bei Facebook über ' +
          'unsere Seite bei Facebook aus. Interaktionen, insbesondere das Nutzen einer Kommentarfunktion oder das ' +
          'Anklicken eines „Like“- oder „Teilen“-Buttons werden ebenfalls an Facebook weitergegeben. Mehr erfahren ' +
          'Sie unter'   ,
      facebookFanpage:'Facebook Fanpage'  ,
      facebookFanpageDesc:'Wir ergänzen unsere Onlinepräsenz in dem sozialen Netzwerk Facebook, welches von Facebook Ireland Ltd., ' +
          '4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland („Facebook“) betrieben wird, um eine sogenannte „Facebook Fanpage“. Dies ermöglicht uns mit dort aktiven Nutzern, Interessenten und Kunden in Kontakt ' +
          'treten zu können und über unsere Unternehmen informieren zu können. Für diese Datenverarbeitung sind ' +
          'wir und Facebook gemeinsame Verantwortliche (Art. 26 Abs. 1 DSGVO). Dies wurde in der sogenannten ' +
          '„Seiten-Insights-Ergänzung bezüglich des Verantwortlichen“ festgelegt. Diese Vereinbarung kann unter ' ,
       facebookFanpageDescPart2:' eingesehen werden. Wir werten statistisch aus, wie Sie unsere Fanpage nutzen. Hierbei erhalten wir von Facebook eine Statistik  personenbezogenen Daten. Ebenfalls können wir keine personenbezogenen Daten einsehen oder abfragen. ' +
          '    Mithilfe der Statistik ist es uns möglich, unsere Fanpage entsprechend der Nutzerbedürfnisse anzupassen  und zu verbessern. Um diese Statistik zu ermöglichen, werden in der Regel Cookies auf Ihrem genutzten ' +
          '      Endgerät gespeichert. Inhalt dieser Cookies sind Ihr Nutzungsverhalten sowie gespeicherte Interessen. Der  beim Öffnen unserer Fanpage gesetzte Cookie hat den Zweck, Sie wiederzuerkennen und die Nutzung ' +
          '   unserer Fanpage zu vereinfachen. Laut Facebook wird dieser Cookie samt dessen gesammelten Daten innerhalb von 90 Tagen gelöscht bzw. anonymisiert.  Grundlage der Verarbeitung Ihrer personenbezogenen Daten während Ihres Besuchs unserer Fanpage ist ' +
          '     unser berechtigtes Interesse nach Art. 6 Abs. 1 Satz 1 Buchstabe f) DSGVO an einer Information der Nutzer und Kommunikation mit den Nutzern. Die Verarbeitung der Daten kann auch außerhalb der EU erfolgen, da ' +
          '      sich einige Server von Facebook auch in den USA befinden.   In der von uns mit Facebook geschlossenen Vereinbarung wurde festgelegt, dass Facebook hauptsächlich ' +
          '      für die Verarbeitung der Insights-Daten verantwortlich ist. Außerdem ist Facebook für das Nachkommen der ' +
          '     Informationspflichten nach Art. 13 und 14 DSGVO sowie die Wahrung Ihrer Betroffenenrechten zuständig. ' +
          '    Sie können Ihre Betroffenenrechte nach Art 12 ff. DSGVO, das Recht auf Löschung nach Art. 17 DSGVO, das ' +
          '    Recht auf Einschränkung der Verarbeitung nach Art. 18 DSGVO, das Widerspruchsrecht nach Art. 21 DSGVO ' +
          '   und Ihr Recht auf Auskunft nach Art. 15 DSGVO unter  ' , 
      facebookFanpageDescPart3:   'einfordern. Weitere Informationen über die Verarbeitung sowie Nutzung der Daten finden Sie unter ' ,
      facebookFanpageDescPart4: '. Auf diesen Seiten finden Sie auch weitere Möglichkeiten zum Schutz Ihrer Privatsphäre' ,
    },
    und:'und',
    instagram: {
      title: 'Datenschutzerklärung für Instagram',
      description: 'Wir ergänzen unsere Onlinepräsenz in dem sozialen Netzwerk Instagram, welches von Facebook Ireland ' +
          'Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland („Facebook“) betrieben wird, um ein ' +
          'sogenanntes „Instagram-Profil“. Dies ermöglicht uns mit dort aktiven Nutzern, Interessenten und Kunden in ' +
          'Kontakt treten zu können und über unsere Unternehmen informieren zu können. Für diese ' +
          'Datenverarbeitung sind wir und Facebook gemeinsame Verantwortliche (Art. 26 Abs. 1 DSGVO). Dies wurde ' +
          'in der sogenannten „Seiten-Insights-Ergänzung bezüglich des Verantwortlichen“ festgelegt. Diese ' +
          'Vereinbarung kann unter ' ,
      description2Part2: ' eingesehen  werden. Wir werten statistisch aus, wie Sie unser Instagram-Profil nutzen. Hierbei erhalten wir von Facebook eine ' +
          'Statistik über die Nutzung unseres Profils, die sog. „Insights“. Diese Statistik beinhaltet jedoch keine personenbezogenen Daten. Ebenfalls können wir keine personenbezogenen Daten einsehen oder abfragen. ' +
          ' Mithilfe der Statistik ist es uns möglich, unser Profil entsprechend der Nutzerbedürfnisse anzupassen und ' +
          'zu verbessern. Um diese Statistik zu ermöglichen, werden in der Regel Cookies auf Ihrem genutzten ' +
          'Endgerät gespeichert. Inhalt dieser Cookies sind Ihr Nutzungsverhalten sowie gespeicherte Interessen. Der ' +
          'beim Öffnen unseres Profils gesetzte Cookie hat den Zweck, Sie wiederzuerkennen und die Nutzung ' +
          'unseres Profils  zu vereinfachen. Laut Facebook wird dieser Cookie samt dessen gesammelten Daten ' +
          'innerhalb von 90 Tagen gelöscht bzw. anonymisiert. ' +
          ' Grundlage der Verarbeitung Ihrer personenbezogenen Daten während Ihres Besuchs unseres Profils ist ' +
          'unser berechtigtes Interesse nach Art. 6 Abs. 1 Satz 1 Buchstabe f) DSGVO an einer Information der Nutzer ' +
          'und Kommunikation mit den Nutzern. Die Verarbeitung der Daten kann auch außerhalb der EU erfolgen, da ' +
          'sich einige Server von Facebook auch in den USA befinden. ' +
          ' In der von uns mit Facebook geschlossenen Vereinbarung wurde festgelegt, dass Facebook hauptsächlich ' +
          'für die Verarbeitung der Insights-Daten verantwortlich ist. Außerdem ist Facebook für das Nachkommen der ' +
          'Informationspflichten nach Art. 13 und 14 DSGVO sowie die Wahrung Ihrer Betroffenenrechten zuständig. ' +
          ' Sie können Ihre Betroffenenrechte nach Art 12 ff. DSGVO, das Recht auf Löschung nach Art. 17 DSGVO, das ' +
          'Recht auf Einschränkung der Verarbeitung nach Art. 18 DSGVO, das Widerspruchsrecht nach Art. 21 DSGVO ' +
          'und Ihr Recht auf Auskunft nach Art. 15 DSGVO unter ',
           description2part3:   ' einfordern.  Weitere Informationen über die Verarbeitung sowie Nutzung der Daten finden Sie unter ' ,
      und_Zu: ' und zu ',
      Unter: ' Unter ', 
      description2part5: ' finden Sie auch weitere Möglichkeiten zum Schutz Ihrer  Privatsphäre.'

    },
    linkedin: {
      title: 'Datenschutzerklärung für LinkedIn',
      description: 'Wir ergänzen unsere Onlinepräsenz in dem sozialen Netzwerk LinkedIn, welches von LinkedIn Ireland Unlimited Company, Wilton Place, Dublin 2, Irland („LinkedIn“) betrieben wird. Dies ermöglicht uns mit dort ' +
          'aktiven Nutzern, Interessenten und Kunden in Kontakt treten zu können und über unsere Unternehmen ' +
          'informieren zu können. Beim Aufruf unseres LinkedIn-Profils erfasst LinkedIn Ihre IP-Adresse und unter ' +
          'Umstände weitere Informationen, die als Cookies gespeichert sind. Diese Daten werden unter Umständen in ' +
          'Länder außerhalb der EU übertragen.  In welchem Maße LinkedIn weitere Daten Ihres Besuchs verarbeitet, wie bspw. Kommentare und weitere ' +
          'Aktivitäten auf der Plattform, ist uns nicht bekannt. Wenn Sie als Nutzer zum Zeitpunkt des Besuchs unseres Profils auf LinkedIn angemeldet sind, ist es LinkedIn möglich nachzuvollziehen, dass Sie unser Profil ' +
          'aufgesucht haben. Dies betrifft auch weitere Aktivitäten im Zusammenhang mit unserem Profil. Grundlage der Verarbeitung Ihrer personenbezogenen Daten während Ihres Besuchs unseres Profils ist ' +
          'unser berechtigtes Interesse nach Art. 6 Abs. 1 Satz 1 Buchstabe f) DSGVO an einer Information der Nutzer ' +
          'und Kommunikation mit den Nutzern. Die Verarbeitung der Daten kann auch außerhalb der EU erfolgen, da ' +
          'sich einige Server von LinkedIn auch in den USA befinden. LinkedIn hat sich dem „Privacy Shield“ ' +
          'angeschlossen und sich somit verpflichtet, ein angemessenes Schutzniveau zu gewährleisten. Findet eine ' +
          'Datenübermittlung an LinkedIn mit Sitz in den USA statt, basiert diese auf Art. 45 DSGVO. Weitere ' +
          'Informationen über die Verarbeitung sowie Nutzung der Daten finden Sie unter:'
    },
    xing: {
      title: 'Datenschutzerklärung für XING',
      description: 'Wir betreiben unter der URL  ',
      descriptionPart2: ' eine  offizielle XING-Seite auf Grundlage von Art. 6 Abs. 1 lit. f) DSGVO, um unser Unternehmen bestmöglich ' +
              'präsentieren zu können und mit Ihnen als Nutzer, Kunden oder Interessenten zu kommunizieren und Sie ' +
          'über unsere angebotenen Leistungen informieren zu können. Beim Aufruf unseres XING-Profils erfasst XING ' +
          'Ihre IP-Adresse und unter Umstände weitere Informationen, die als Cookies gespeichert sind. Diese Daten ' +
          'werden unter Umständen in Länder außerhalb der EU übertragen.  In welchem Maße XING weitere Daten Ihres Besuchs verarbeitet, wie bspw. Kommentare und weitere ' +
          'Aktivitäten auf der Plattform, ist uns nicht bekannt. Wenn Sie als Nutzer zum Zeitpunkt des Besuchs ' +
          'unseres Profils auf XING angemeldet sind, ist es XING möglich nachzuvollziehen, dass Sie unser Profil ' +
          'aufgesucht haben. Dies betrifft auch weitere Aktivitäten im Zusammenhang mit unserem Profil.  Grundlage der Verarbeitung Ihrer personenbezogenen Daten während Ihres Besuchs unseres Profils ist ' +
          'unser berechtigtes Interesse nach Art. 6 Abs. 1 Satz 1 Buchstabe f) DSGVO an einer Information der Nutzer ' +
          'und Kommunikation mit den Nutzern. Die Verarbeitung der Daten kann auch außerhalb der EU erfolgen, da ' +
          'sich einige Server von XING auch in den USA befinden. XING hat sich dem „Privacy Shield“ angeschlossen ' +
          'und sich somit verpflichtet, ein angemessenes Schutzniveau zu gewährleisten. Findet eine ' +
          'Datenübermittlung an XING mit Sitz in den USA statt, basiert diese auf Art. 45 DSGVO. Weitere ' +
          'Informationen über die Verarbeitung sowie Nutzung der Daten finden Sie unter '
    }
  },
  en: {
    facebook: {
      title: 'Privacy Policy for Facebook',
      description: 'Our website uses functions of Facebook Inc., 1601 S. California Ave, Palo Alto, CA 94304, USA. When you call ' +
          'up our pages with Facebook plug-ins, a connection is established between your browser and the Facebook ' +
          'servers. During this process, data is already transferred to Facebook. If you have a Facebook account, this ' +
          'data can even be linked to it. If you do not wish this data to be associated with your Facebook account, ' +
          'please log out of Facebook before visiting our site. Interactions, in particular the use of a comment function ' +
          'or the clicking of a "Like" or "Share" button, are also passed on to Facebook. You can find more information ' +
          'at'   ,
      facebookFanpage:'Facebook Fan page'  ,
      facebookFanpageDesc:'We supplement our online presence in the social network Facebook, which is operated by Facebook Ireland ' +
          'Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Ireland ("Facebook"), with a so-called "Facebook ' +
          'Fan page". This enables us to get in touch with users, interested parties and customers who are active there ' +
          'and to inform them about our company. We and Facebook are joint controllers for this data processing ' +
          '(Article 26, paragraph 1 of the GDPR). This was stipulated in the so-called "Page Insights Supplement ' +
          'regarding the controller". This agreement can be viewed under ', 
      facebookFanpageDescPart2:  'can be viewed. We statistically evaluate how you use our fan page. In doing so, we receive statistics from Facebook about ' +
            'the use of our fan page, the so-called "Facebook Insights". However, these statistics do not contain any ' +
            'personal data. Likewise, we cannot view or query any personal data. ' +
            'With the help of the statistics, we are able to adapt and improve our profile according to user needs. In order ' +
            'to make these statistics possible, cookies are usually stored on your end device.The content of these cookies ' +
            'is your usage behaviour and stored interests. The cookie set when you open our fan page has the purpose of ' +
            'recognising you and making it easier to use our fan page. According to Facebook, this cookie and the data it ' +
            'collects will be deleted or anonymised within 90 days. ' +
            'The basis for the processing of your personal data during your visit to our fan page is our legitimate interest ' +
            'according to Article 6, paragraph 1, sentence 1, letter f) of the GDPR in informing users and communicating ' +
            'with users. The processing of data may also take place outside the EU, as some Facebook servers are also ' +
            'located in the USA. ' +
            'The agreement we have concluded with Facebook stipulates that Facebook is primarily responsible for ' +
            'processing the Insights data. In addition, Facebook is responsible for complying with the information ' +
            'obligations pursuant to Articles 13 and 14 of the GDPR and for safeguarding your data subject rights. You ' +
            'can exercise your data subject rights according to Article 12 ff. of the GDPR, the right to erasure according to ' +
            'Article 17 of the GDPR, the right to restriction of processing according to Article 18 of the GDPR, the right to ' +
            'object according to Article 21 of the GDPR and your right to information according to Article 15 of the GDPR  ' ,

      facebookFanpageDescPart3:   'demand. You can find more information about processing and usage of data under' ,
      facebookFanpageDescPart4: '. will also find further options for protecting your privacy on these pages.' ,
   
      
      
    },
    und:'and',
    instagram: {
      title: 'Privacy Policy for Instagram',
      description: 'We supplement our online presence in the social network Instagram, which is operated by Facebook Ireland ' +
          'Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Ireland ("Facebook"), with a so-called “Instagram ' +
          'Profile". This enables us to get in touch with users, interested parties and customers who are active there ' +
          'and to inform them about our company. We and Facebook are joint controllers for this data processing ' +
          '(Article 26, paragraph 1 of the GDPR). This was stipulated in the so-called "Page Insights Supplement ' +
          'regarding the controller". This agreement can be viewed under '     ,
      description2Part2: ' can be viewed.  We statistically evaluate how you use our Instagram profile. In doing so, we receive statistics from Facebook ' +
              'about the use of our profile, the so-called "Insights". However, these statistics do not contain any personal ' +
          'data. Likewise, we cannot view or query any personal data. ' +
          ' With the help of the statistics, we are able to adapt and improve our profile according to user needs. In order ' +
          'to make these statistics possible, cookies are usually stored on your end device. The content of these ' +
          'cookies is your usage behaviour and stored interests. The cookie set when you open our profile has the ' +
          'purpose of recognising you and making it easier to use our profile. According to Facebook, this cookie and ' +
          'the data it collects will be deleted or anonymised within 90 days.  The basis for the processing of your personal data during your visit to our profile is our legitimate interest ' +
          'according to Article 6, paragraph 1, sentence 1, letter f) of the GDPR in informing users and communicating ' +
          'with users. The processing of data may also take place outside the EU, as some Facebook servers are also ' +
          'located in the USA.  The agreement we have concluded with Facebook stipulates that Facebook is primarily responsible for ' +
          'processing the Insights data. In addition, Facebook is responsible for complying with the information ' +
          'obligations pursuant to Articles 13 and 14 of the GDPR and for safeguarding your data subject rights. You ' +
          'can exercise your data subject rights according to Article 12 ff. of the GDPR, the right to erasure according to ' +
          'Article 17 of the GDPR, the right to restriction of processing according to Article 18 of the GDPR, the right to ' +
          'object according to Article 21 of the GDPR and your right to information according to Article 15 of the GDPR ' +
          'at '  ,
      description2part3:   '  demand. You can find more information about processing and usage of data under  ' ,
      und_Zu: ' und zu ',
      Unter: ' Unter ',
      description2part5: '. You will also find further options for protecting your  privacy under'
    },
    linkedin: {
      title: 'Privacy Policy for LinkedIn',
      description: 'We supplement our online presence on the social network LinkedIn, which is operated by LinkedIn Ireland ' +
          'Un¬limited Company, Wilton Place, Dublin 2, Ireland (“Lin¬kedIn”). This enables us to get in touch with active ' +
          'users, interested parties and customers and to provide information about our companies. When you access ' +
          'our LinkedIn profile, LinkedIn collects your IP address and, under certain circumstances, other information ' +
          'that is stored as cookies. This data may be transferred to countries outside the EU. ' +
          'We do not know to what extent LinkedIn processes other data from your visit, such as comments and other ' +
          'activities on the platform. If you are logged in as a user on LinkedIn at the time of visiting our profile, ' +
          'LinkedIn will be able to track that you have visited our profile. This also applies to other activities related to ' +
          'our profile. ' +
          'The basis for processing your personal data during your visit to our profile is our legitimate interest in ' +
          'providing information to users and communicating with users in accordance with Article 6 Paragraph 1 ' +
          'Sentence 1 Letter f) GDPR. The data can also be processed outside the EU, as some LinkedIn servers are also ' +
          'located in the USA. LinkedIn has joined the “Privacy Shield” and is therefore committed to ensuring an ' +
          'appropriate level of protection. If data is transferred to LinkedIn based in the USA, this is based on Art. 45 ' +
          'GDPR. Further information about the processing and use of data can be found at: '
    },
    xing: {
      title: 'Privacy Policy for XING',
      description: 'Under the URL ',
      descriptionPart2: 'we operate an official XING page on the basis of Article 6, paragraph 1, lit. f) of the GDPR in order to be able to present our ' +
              'company in the best possible way and to communicate with you as a user, customer or interested party and ' +
          'to be able to inform you about the services we offer. When you access our XING profile, XING collects your IP ' +
          'address and, under certain circumstances, other information that is stored as cookies. This data may be ' +
          'transferred to countries outside the EU. ' +
          'We do not know to what extent XING processes further data from your visit, such as comments and other ' +
          'activities on the platform. If you are logged in as a user on XING at the time of visiting our profile, it is ' +
          'possible for XING to track that you have visited our profile. This also applies to further activities in ' +
          'connection with our profile. ' +
          'The basis for the processing of your personal data during your visit to our profile is our legitimate interest ' +
          'according to Article 6, paragraph 1, sentence 1, letter f) of the GDPR in informing users and communicating ' +
          'with users. The processing of data may also take place outside the EU, as some XING servers are also ' +
          'located in the USA. XING has joined the "Privacy Shield" and has thus committed itself to guaranteeing an ' +
          'adequate level of protection. If data is transferred to XING, which is based in the USA, this is based on Article ' +
          '45 of the GDPR. You can find more information about processing and usage of data under: ',
    }
  },
  ar: {
    facebook: {
      title: 'سياسة الخصوصية لفيسبوك',
      description: 'يستخدم موقعنا الإلكتروني وظائف من Facebook Inc.، 1601 S. California Ave, Palo Alto, CA 94304, USA. ' +
          'عند زيارة صفحاتنا التي تحتوي على إضافات (بلجنز) من Facebook، يتم إنشاء اتصال بين متصفحك وخوادم Facebook. في هذه المرحلة، يتم نقل بيانات إلى Facebook. إذا كان لديك حساب على Facebook، يمكن ربط هذه البيانات بحسابك. إذا كنت لا ترغب في ربط هذه البيانات بحسابك على Facebook، يُرجى تسجيل الخروج من Facebook قبل زيارة موقعنا عبر صفحتنا. ' +
          'أي تفاعلات مع الموقع، بما في ذلك استخدام وظيفة التعليقات أو النقر على زر "إعجاب" أو "مشاركة"، سيتم إرسالها أيضًا إلى Facebook. ' +
          'لمزيد من المعلومات، يمكنك الاطلاع على سياسة الخصوصية الخاصة بـ Facebook.' ,
    facebookFanpage:'صفحة المعجبين على Facebook'  ,
    facebookFanpageDesc:'نحن نكمل وجودنا على الإنترنت في شبكة التواصل الاجتماعي Facebook، التي تديرها Facebook Ireland Ltd.، 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Ireland ("Facebook")، باستخدام ما يُسمى "صفحة المعجبين على Facebook". وهذا يمكننا من التواصل مع المستخدمين والمهتمين والعملاء الذين ينشطون هناك وإعلامهم بشركتنا. نحن و Facebook نعتبر معالِجَيْن مشتركين لهذه البيانات (المادة 26، الفقرة 1 من اللائحة العامة لحماية البيانات - GDPR). تم تحديد ذلك في ما يُسمى "إضافة صفحة Insights بخصوص المعالج". يمكن الاطلاع على هذه الاتفاقية عبر الرابط التالي: https://www.facebook.com/legal/terms/page_controller_addendum. ' +
        ' ' +
        'نقوم بتقييم إحصائي لكيفية استخدامك لصفحة المعجبين الخاصة بنا. في هذا السياق، نتلقى إحصائيات من Facebook حول استخدام صفحتنا، والمعروفة باسم "Facebook Insights". ومع ذلك، لا تحتوي هذه الإحصائيات على أي بيانات شخصية. وبالمثل، لا يمكننا الاطلاع على أو استعلام أي بيانات شخصية. ' +
        ' ' +
        'من خلال الإحصائيات، يمكننا تعديل وتحسين ملفنا الشخصي وفقًا لاحتياجات المستخدمين. ولجعل هذه الإحصائيات ممكنة، يتم عادةً تخزين ملفات تعريف الارتباط على جهازك الطرفي. تحتوي محتويات هذه الملفات على سلوك الاستخدام واهتماماتك المخزنة. يتم تعيين ملف تعريف الارتباط عند فتحك لصفحة المعجبين الخاصة بنا بهدف التعرف عليك وتسهيل استخدامك لصفحتنا. وفقًا لـ Facebook، سيتم حذف هذا الملف أو جعله مجهول الهوية خلال 90 يومًا. ' +
        ' ' +
        'الأساس القانوني لمعالجة بياناتك الشخصية خلال زيارتك لصفحة المعجبين الخاصة بنا هو مصلحتنا المشروعة وفقًا للمادة 6، الفقرة 1، الجملة 1، الحرف (و) من اللائحة العامة لحماية البيانات (GDPR)، وهي إعلام المستخدمين والتواصل معهم. قد يتم أيضًا معالجة البيانات خارج الاتحاد الأوروبي، حيث أن بعض خوادم Facebook تقع في الولايات المتحدة. ' +
        ' ' +
        'تنص الاتفاقية التي أبرمناها مع Facebook على أن Facebook هو المسؤول الأساسي عن معالجة بيانات Insights. بالإضافة إلى ذلك، يتحمل Facebook المسؤولية عن الامتثال لالتزامات المعلومات وفقًا للمادتين 13 و14 من اللائحة العامة لحماية البيانات (GDPR) وضمان حقوقك كصاحب بيانات. يمكنك ممارسة حقوقك وفقًا للمادة 12 وما بعدها من اللائحة العامة لحماية البيانات (GDPR)، مثل الحق في الحذف وفقًا للمادة 17 من اللائحة، والحق في تقييد المعالجة وفقًا للمادة 18، والحق في الاعتراض وفقًا للمادة 21، وحقك في الحصول على المعلومات وفقًا للمادة 15 من اللائحة عبر الرابط: https://www.facebook.com/about/privacy. ' +
        ' ' +
        'يمكنك العثور على مزيد من المعلومات حول معالجة البيانات واستخدامها عبر الروابط التالية: ' +
        'https://www.facebook.com/about/privacy/ و https://www.facebook.com/legal/terms/information_about_page_insights_data. ستجد أيضًا خيارات إضافية لحماية خصوصيتك على هذه الصفحات.'    ,
      facebookFanpageDescPart2: '',
      facebookFanpageDescPart3: '',
      facebookFanpageDescPart4 : '',
     },

    und:'and',
    instagram: {
      title: 'سياسة الخصوصية لإنستغرام',
      description: 'نحن نكمل وجودنا على الإنترنت في شبكة التواصل الاجتماعي Instagram، التي تديرها Facebook Ireland Ltd.، 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Ireland ("Facebook")، باستخدام ما يُسمى "حساب Instagram". وهذا يتيح لنا التواصل مع المستخدمين المهتمين والعملاء النشطين على هذه الشبكة وإعلامهم عن شركتنا. نحن و Facebook نعتبر معالِجَيْن مشتركين لهذه البيانات (المادة 26، الفقرة 1 من اللائحة العامة لحماية البيانات - GDPR). تم تحديد ذلك في ما يُسمى "إضافة صفحة Insights بخصوص المعالج". يمكن الاطلاع على هذه الاتفاقية عبر الرابط التالي: https://www.facebook.com/legal/terms/page_controller_addendum. ' +
          ' ' +
          'نقوم بتقييم إحصائياً لكيفية استخدامك لحسابنا على Instagram. في هذا السياق، نتلقى إحصائيات من Facebook حول استخدام حسابنا، والمعروفة باسم "Instagram Insights". ومع ذلك، لا تحتوي هذه الإحصائيات على أي بيانات شخصية. وبالمثل، لا يمكننا الاطلاع على أو استعلام أي بيانات شخصية. ' +
          ' ' +
          'من خلال هذه الإحصائيات، يمكننا تعديل وتحسين ملفنا الشخصي بما يتناسب مع احتياجات المستخدمين. ولجعل هذه الإحصائيات ممكنة، يتم عادةً تخزين ملفات تعريف الارتباط على جهازك الطرفي. تحتوي محتويات هذه الملفات على سلوك الاستخدام واهتماماتك المخزنة. يتم تعيين ملف تعريف الارتباط عند فتحك لحسابنا على Instagram بهدف التعرف عليك وتسهيل استخدامك للحساب. وفقًا لـ Facebook، سيتم حذف هذا الملف أو جعله مجهول الهوية خلال 90 يومًا. ' +
          ' ' +
          'الأساس القانوني لمعالجة بياناتك الشخصية خلال زيارتك لحسابنا على Instagram هو مصلحتنا المشروعة وفقًا للمادة 6، الفقرة 1، الجملة 1، الحرف (و) من اللائحة العامة لحماية البيانات (GDPR)، وهي إعلام المستخدمين والتواصل معهم. قد يتم أيضًا معالجة البيانات خارج الاتحاد الأوروبي، حيث أن بعض خوادم Facebook تقع في الولايات المتحدة. ' +
          ' ' +
          'تنص الاتفاقية التي أبرمناها مع Facebook على أن Facebook هو المسؤول الأساسي عن معالجة بيانات Insights. بالإضافة إلى ذلك، يتحمل Facebook المسؤولية عن الامتثال لالتزامات المعلومات وفقًا للمادتين 13 و14 من اللائحة العامة لحماية البيانات (GDPR) وضمان حقوقك كصاحب بيانات. يمكنك ممارسة حقوقك وفقًا للمادة 12 وما بعدها من اللائحة العامة لحماية البيانات (GDPR)، مثل الحق في الحذف وفقًا للمادة 17 من اللائحة، والحق في تقييد المعالجة وفقًا للمادة 18، والحق في الاعتراض وفقًا للمادة 21، وحقك في الحصول على المعلومات وفقًا للمادة 15 من اللائحة عبر الرابط: https://www.facebook.com/about/privacy. ' +
          ' ' +
          'يمكنك العثور على مزيد من المعلومات حول معالجة البيانات واستخدامها عبر الروابط التالية: ' +
          'https://help.instagram.com/519522125107875 و https://www.facebook.com/legal/terms/information_about_page_insights_data. ' +
          'ستجد أيضًا خيارات إضافية لحماية خصوصيتك عبر الرابط: https://help.instagram.com/196883487377501.' ,

      description2Part2: ' eingesehen  werden. Wir werten statistisch aus, wie Sie unser Instagram-Profil nutzen. Hierbei erhalten wir von Facebook eine ' +
          'Statistik über die Nutzung unseres Profils, die sog. „Insights“. Diese Statistik beinhaltet jedoch keine personenbezogenen Daten. Ebenfalls können wir keine personenbezogenen Daten einsehen oder abfragen. ' +
          ' Mithilfe der Statistik ist es uns möglich, unser Profil entsprechend der Nutzerbedürfnisse anzupassen und ' +
          'zu verbessern. Um diese Statistik zu ermöglichen, werden in der Regel Cookies auf Ihrem genutzten ' +
          'Endgerät gespeichert. Inhalt dieser Cookies sind Ihr Nutzungsverhalten sowie gespeicherte Interessen. Der ' +
          'beim Öffnen unseres Profils gesetzte Cookie hat den Zweck, Sie wiederzuerkennen und die Nutzung ' +
          'unseres Profils  zu vereinfachen. Laut Facebook wird dieser Cookie samt dessen gesammelten Daten ' +
          'innerhalb von 90 Tagen gelöscht bzw. anonymisiert. ' +
          ' Grundlage der Verarbeitung Ihrer personenbezogenen Daten während Ihres Besuchs unseres Profils ist ' +
          'unser berechtigtes Interesse nach Art. 6 Abs. 1 Satz 1 Buchstabe f) DSGVO an einer Information der Nutzer ' +
          'und Kommunikation mit den Nutzern. Die Verarbeitung der Daten kann auch außerhalb der EU erfolgen, da ' +
          'sich einige Server von Facebook auch in den USA befinden. ' +
          ' In der von uns mit Facebook geschlossenen Vereinbarung wurde festgelegt, dass Facebook hauptsächlich ' +
          'für die Verarbeitung der Insights-Daten verantwortlich ist. Außerdem ist Facebook für das Nachkommen der ' +
          'Informationspflichten nach Art. 13 und 14 DSGVO sowie die Wahrung Ihrer Betroffenenrechten zuständig. ' +
          ' Sie können Ihre Betroffenenrechte nach Art 12 ff. DSGVO, das Recht auf Löschung nach Art. 17 DSGVO, das ' +
          'Recht auf Einschränkung der Verarbeitung nach Art. 18 DSGVO, das Widerspruchsrecht nach Art. 21 DSGVO ' +
          'und Ihr Recht auf Auskunft nach Art. 15 DSGVO unter ',
      description2part3:   ' einfordern.  Weitere Informationen über die Verarbeitung sowie Nutzung der Daten finden Sie unter ' ,
      und_Zu: ' und zu ',
      Unter: ' Unter ',
      description2part5: ' finden Sie auch weitere Möglichkeiten zum Schutz Ihrer  Privatsphäre.'
    },
    linkedin: {
      title: 'سياسة الخصوصية للينكد إن',
      description: 'نحن نكمل وجودنا على الإنترنت في شبكة التواصل الاجتماعي لينكد إن، التي تديرها شركة لينكد إن أيرلندا المحدودة، ويلتون بليس، دبلن 2، أيرلندا ("لينكد إن"). يتيح لنا ذلك التواصل مع المستخدمين النشطين، الأطراف المهتمة والعملاء وتقديم المعلومات حول شركاتنا. عند الوصول إلى ملفنا الشخصي على لينكد إن، يقوم لينكد إن بجمع عنوان IP الخاص بك وأحيانًا معلومات أخرى يتم تخزينها كملفات تعريف ارتباط (كوكيز). قد يتم نقل هذه البيانات إلى دول خارج الاتحاد الأوروبي. ' +
          ' ' +
          'نحن لا نعلم مدى معالجة لينكد إن للبيانات الأخرى الناتجة عن زيارتك، مثل التعليقات والأنشطة الأخرى على المنصة. إذا كنت مسجلاً كمستخدم على لينكد إن في وقت زيارة ملفنا الشخصي، سيتمكن لينكد إن من تتبع زيارتك لملفنا الشخصي. ينطبق هذا أيضًا على الأنشطة الأخرى المتعلقة بملفنا الشخصي. ' +
          ' ' +
          'الأساس القانوني لمعالجة بياناتك الشخصية أثناء زيارتك لملفنا الشخصي هو مصلحتنا المشروعة في تقديم المعلومات للمستخدمين والتواصل معهم وفقًا للمادة 6، الفقرة 1، الجملة 1، الحرف (و) من اللائحة العامة لحماية البيانات (GDPR). يمكن أيضًا معالجة البيانات خارج الاتحاد الأوروبي، حيث أن بعض خوادم لينكد إن تقع في الولايات المتحدة. وقد انضمت لينكد إن إلى "درع الخصوصية" (Privacy Shield) وبالتالي التزمت بضمان مستوى حماية مناسب. إذا تم نقل البيانات إلى لينكد إن الموجود في الولايات المتحدة، فإن ذلك يستند إلى المادة 45 من اللائحة العامة لحماية البيانات (GDPR). ' +
          ' ' +
          'لمزيد من المعلومات حول معالجة واستخدام البيانات، يمكن الاطلاع على: سياسة الخصوصية على لينكد إن.'
    },
    xing: {
      title: 'سياسة الخصوصية لـ XING',
      description: ' ' +
          'ChatGPT a dit : ' +
          'ChatGPT ' +
          'نحن ندير صفحة رسمية على XING تحت الرابط التالي: https://www.xing.com/pages/novobit-group-gmbh-ehemals-tojaq-gmbh استنادًا إلى المادة 6، الفقرة 1، الحرف (و) من اللائحة العامة لحماية البيانات (GDPR)، بهدف تقديم شركتنا بأفضل طريقة ممكنة والتواصل معك كمستخدم أو عميل أو مهتم، وإبلاغك بالخدمات التي نقدمها. عند زيارة ملفنا الشخصي على XING، يقوم XING بتسجيل عنوان IP الخاص بك وأحيانًا معلومات أخرى يتم تخزينها كملفات تعريف ارتباط (كوكيز). قد يتم نقل هذه البيانات إلى دول خارج الاتحاد الأوروبي. ' +
          ' ' +
          'لا نعرف مدى معالجة XING للبيانات الأخرى المتعلقة بزيارتك، مثل التعليقات أو الأنشطة الأخرى على المنصة. إذا كنت مسجلاً على XING أثناء زيارتك لصفحتنا، فيمكن لـ XING تتبع زيارتك لملفنا الشخصي. ينطبق ذلك أيضًا على الأنشطة الأخرى ذات الصلة بصفحتنا. ' +
          ' ' +
          'الأساس القانوني لمعالجة بياناتك الشخصية خلال زيارتك لصفحتنا على XING هو مصلحتنا المشروعة وفقًا للمادة 6، الفقرة 1، الجملة 1، الحرف (و) من اللائحة العامة لحماية البيانات (GDPR)، والتي تهدف إلى إعلام المستخدمين والتواصل معهم. قد يتم أيضًا معالجة البيانات خارج الاتحاد الأوروبي، حيث أن بعض خوادم XING تقع في الولايات المتحدة. وقد انضم XING إلى "درع الخصوصية" (Privacy Shield)، وبالتالي التزم بضمان مستوى حماية مناسب. إذا تم نقل البيانات إلى XING الذي يقع في الولايات المتحدة، فإن ذلك يستند إلى المادة 45 من اللائحة العامة لحماية البيانات (GDPR). ' +
          ' ' +
          'لمزيد من المعلومات حول معالجة واستخدام البيانات، يمكنك الاطلاع على المزيد هنا.'             ,
      descriptionPart2 : ''
    }
  }
};
