import { useState } from 'react';
import ServiceModal from './SoftwareServices/ServiceModal.tsx';
import VideoCard from './SoftwareServices/VideoCard.tsx';
import ImageCard from './SoftwareServices/ImageCard.tsx';
import { useLanguage } from '../contexts/LanguageContext';

const SoftwareServices = () => {
  const [showPortfolioModal, setShowPortfolioModal] = useState(false);
  const [showLifecycleModal, setShowLifecycleModal] = useState(false);
  const [showIngoModal, setShowIngoModal] = useState(false);
  const [showNadaModal, setShowNadaModal] = useState(false);
  const [showSerielleFertigungModal, setShowSerielleFertigungModal] = useState(false);
  const [showReportingToolModal, setShowReportingToolModal] = useState(false);
  const [showInfoSecurityModal, setShowInfoSecurityModal] = useState(false);
  const [showSignageSystemModal, setShowSignageSystemModal] = useState(false);
  const { translations } = useLanguage();

  return (
    <div id="software-services" className="py-5 bg-white">
      <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-light text-gray-900 mb-5">{translations.software}</h2>
        
        {/* First row */}
        <div className="grid grid-cols-5 gap-3">
          {/* Portfolio */}
          <div 
            className="relative group col-span-2 cursor-pointer"
            onClick={() => setShowPortfolioModal(true)}
          >
            <div className="relative h-full overflow-hidden rounded-lg">
              <img 
                src={translations.softwareservices_1_1} 
                alt="Developer" 
                className="w-full h-full object-cover transition-opacity duration-300 group-hover:opacity-0"
              />
              <img
                  src={translations.softwareservices_1_1}
                  alt="Developer Alternate" 
                className="absolute inset-0 w-full h-full object-cover opacity-0 group-hover:opacity-100 transition-opacity duration-300"
              />
              <div className="absolute inset-0 bg-black/30 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <div className="absolute bottom-6 left-6 text-white">
                  <p className="text-sm">{translations.ClickLearnMore}</p>
                </div>
              </div>
            </div>
          </div>

          {/* Lifecycle */}
          <div 
            className="relative group col-span-2 cursor-pointer"
            onClick={() => setShowLifecycleModal(true)}
          >
            <div className="relative h-full overflow-hidden rounded-lg bg-gray-800">
              <img 
                src={translations.softwareservices_2_1}
                alt="Lifecycle" 
                className="w-full h-full object-cover transition-opacity duration-300 group-hover:opacity-0"
              />
              <img 
                src={translations.softwareservices_2_1}
                alt="Lifecycle Alternate" 
                className="absolute inset-0 w-full h-full object-cover opacity-0 group-hover:opacity-100 transition-opacity duration-300"
              />
              <div className="absolute inset-0 bg-black/30 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <div className="absolute bottom-6 left-6 text-white">
                  <p className="text-sm">{translations.ClickLearnMore}</p>
                </div>
              </div>
            </div>
          </div>

          {/* Ingo */}
          <div 
            className="relative group col-span-1 cursor-pointer"
            //onClick={() => setShowIngoModal(true)}
          >
            <div className="relative h-full overflow-hidden rounded-lg">
              <img 
                src="../../assets/Pictures/Ingo_Ruebel_Software-Entwicklung_novobit.jpg" 
                alt="Automation" 
                className="w-full h-full object-cover transition-opacity duration-300 group-hover:opacity-0"
              />
              <img 
                src={translations.softwareservices_3}
                alt="Automation Alternate" 
                className="absolute inset-0 w-full h-full object-cover opacity-0 group-hover:opacity-100 transition-opacity duration-300"
              />
            </div>
          </div>
        </div>

        {/* Second row */}
        <div className="grid grid-cols-5  gap-3 mt-3">
          {/* Nada */}
          <div 
            className="relative group col-span-1 cursor-pointer"
            //onClick={() => setShowNadaModal(true)}
          >
            <div className="relative h-full overflow-hidden rounded-lg">
              <img 
                src="../../assets/Pictures/Nada_Hamzaoui_novobit_Braunschweig.jpg" 
                alt="Analytics" 
                className="w-full h-full object-cover transition-opacity duration-300 group-hover:opacity-0"
              />
              <img
                  src={translations.softwareservices_4} 
                alt="Analytics Alternate" 
                className="absolute inset-0 w-full h-full object-cover opacity-0 group-hover:opacity-100 transition-opacity duration-300"
              />
            </div>
          </div>

          {/* Planning Software Video */}
          <div className="relative group col-span-2">
            <VideoCard
              videoSrc="../../assets/Animatics/Planungssoftware_Produktion_novobit.mp4"
              altImageSrc="/hero-bg.jpg"
            />
          </div>

          {/* Serielle Fertigung */}
          <div 
            className="relative group col-span-2 cursor-pointer"
            onClick={() => setShowSerielleFertigungModal(true)}
          >
            <div className="relative h-full overflow-hidden rounded-lg">
              <img 
                src="../../assets/Pictures/Serielle_Fertigung.jpg" 
                alt="Serielle Fertigung" 
                className="w-full h-full object-cover transition-opacity duration-300 group-hover:opacity-0"
              />
              <img 
                src="../../assets/Pictures/Serielle_Fertigung.jpg" 
                alt="Serielle Fertigung Alternate" 
                className="absolute inset-0 w-full h-full object-cover opacity-0 group-hover:opacity-100 transition-opacity duration-300"
              />
              <div className="absolute inset-0 bg-black/30 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <div className="absolute bottom-6 left-6 text-white">
                  <p className="text-sm">{translations.ClickLearnMore}</p>
                </div>
              </div>
            </div>
          </div>

          {/* Reporting Tool Video */}
          <div className="relative group col-span-2">
            <VideoCard
              videoSrc="../../assets/Animatics/Reporting-Tool_novobit.mp4"
              altImageSrc="/hero-bg.jpg"
              hoverImageSrc="../../assets/Pictures/Reporting-Tool_novobit.jpg"
              title=" "
              onClick={() => setShowReportingToolModal(true)}
            />
          </div>

          {/* Information Security */}
          <div className="relative group col-span-1">
            <ImageCard
              imageSrc="../../assets/Pictures/Informationssicherheit_novobit.jpg"
              hoverImageSrc={translations.softwareservices_7}
              title="Informationssicherheit"
              //onClick={() => setShowInfoSecurityModal(true)}
            />
          </div>

          {/* Signage System Video */}
          <div className="relative group col-span-2">
            <VideoCard
              videoSrc="../../assets/Animatics/Signage-System_novobit.mp4"
              altImageSrc="/hero-bg.jpg"
              hoverImageSrc="../../assets/Pictures/Signage-System_novobit.jpg"
              title=""
              onClick={() => setShowSignageSystemModal(true)}
            />
          </div>
        </div>
      </div>

      {/* Modals */}
      <ServiceModal 
        isOpen={showPortfolioModal}
        onClose={() => setShowPortfolioModal(false)}
        title=""
        imageSrc={translations.softwareservices_1_2} 
        description=""
        ctaLink="#"
      />

      <ServiceModal 
        isOpen={showLifecycleModal}
        onClose={() => setShowLifecycleModal(false)}
        title=""
        imageSrc={translations.lifeCycle_1_2} 
        description=""
      />

      <ServiceModal 
        isOpen={showIngoModal}
        onClose={() => setShowIngoModal(false)}
        title=""
        imageSrc="../../assets/_Mouse-over/Ingo_Ruebel_Software-Projekte_1.png"
        description=""
      />

      <ServiceModal 
        isOpen={showNadaModal}
        onClose={() => setShowNadaModal(false)}
        title="Software Analytics"
        imageSrc="../../assets/Pictures/Nada_Hamzaoui_Software-Entwicklung_novobit.jpg"
        description="Unsere Analytics-Lösungen ermöglichen datengetriebene Entscheidungen und tiefe Einblicke in Ihre Geschäftsprozesse. Mit modernsten Technologien und KI-gestützten Analysen helfen wir Ihnen, verborgene Potenziale zu erkennen und Ihre Performance zu optimieren."
        ctaText="Mehr über Analytics"
        ctaLink="#"
      />

      <ServiceModal 
        isOpen={showSerielleFertigungModal}
        onClose={() => setShowSerielleFertigungModal(false)}
        title=""
        imageSrc={translations.softwareservices_5}
        description="Unsere Lösungen für die serielle Fertigung optimieren Produktionsprozesse und steigern die Effizienz in der Massenproduktion. Mit modernster Technologie und intelligenter Automatisierung unterstützen wir Sie bei der Optimierung Ihrer Fertigungslinien."
        ctaText="Mehr über Serielle Fertigung"
        ctaLink="#"
      />

      <ServiceModal 
        isOpen={showReportingToolModal}
        onClose={() => setShowReportingToolModal(false)}
        title=""
        imageSrc={translations.softwareservices_6}
        description="Unser leistungsstarkes Reporting Tool bietet umfassende Einblicke und Analysen für datengesteuerte Entscheidungen. Mit intuitiver Benutzeroberfläche und flexiblen Anpassungsmöglichkeiten ermöglichen wir effizientes Berichtswesen und transparente Geschäftsprozesse."
        ctaText="Mehr über Reporting"
        ctaLink="#"
      />

      <ServiceModal 
        isOpen={showInfoSecurityModal}
        onClose={() => setShowInfoSecurityModal(false)}
        title=""
        imageSrc="../../assets/_Pop-ups/Planungssoftware_Reporting_Tool.png"
        description="Unsere Informationssicherheitslösungen schützen Ihre wertvollen Daten und Systeme vor modernen Bedrohungen. Mit einem ganzheitlichen Ansatz und modernsten Sicherheitstechnologien gewährleisten wir die Vertraulichkeit, Integrität und Verfügbarkeit Ihrer Informationen."
        ctaText="Mehr über Informationssicherheit"
        ctaLink="#"
      />

      <ServiceModal 
        isOpen={showSignageSystemModal}
        onClose={() => setShowSignageSystemModal(false)}
        title=""
        imageSrc={translations.softwareservices_8}
        description="Unser digitales Signage System revolutioniert die Art und Weise, wie Informationen in öffentlichen Räumen präsentiert werden. Mit dynamischen Inhalten, Echtzeit-Updates und intelligenter Steuerung bieten wir eine zukunftsweisende Lösung für moderne Kommunikation."
        ctaText="Mehr über Signage System"
        ctaLink="#"
      />
    </div>
  );
};

export default SoftwareServices;
