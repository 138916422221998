export const serverLogsTranslations = {
  de: {
    title: 'Server-Log-Files',
    description: 'Der Provider dieser Seiten erhebt und speichert ggfs. automatisch Informationen in sogenannten Server-Log-Files, die Ihr Browser automatisch an den Server übermittelt. Diese Daten werden u. U. für statistische\n' +
        'Zwecke genutzt. Üblicherweise senden Sie uns beim Besuch dieser Website die folgenden Informationen\n' +
        'mit:\n',
    dataTypes: [
      'IP-Adresse',
      'Browsertyp und Browserversion',
      'Ihr Betriebssystem',
      'Referrer URL',
      'Hostname des zugreifenden Rechners',
      'Uhrzeit der Serveranfrage'
    ],
    usage: 'Diese Daten sind allerdings nicht bestimmten Personen zuzuordnen, jedoch ist eine spätere Zuordnung bei\n' +
        'Kenntnis Ihrer IP-Adresse theoretisch denkbar, wenn Sie uns bspw. über dieselbe IP-Adresse eine E-Mail mit\n' +
        'weiteren persönlichen Daten senden würden. Wir selbst werten diese Server-Log-Files allerdings nicht aus\n' +
        'und verwenden diese Daten auch nicht. Außerdem speichern wir solche Daten nicht. Wir verarbeiten\n' +
        'ausschließlich Daten, die Sie uns freiwillig und bewusst übermittelt haben und nur dem Zwecke des\n' +
        'Kommunikationsaustausches dienen. Andere Daten sind für uns nicht von Interesse. Bei Kenntnis einer\n' +
        'rechtswidrigen Nutzung dieser Server-Log-Files behalten wir uns allerdings nach Prüfung die Weitergabe\n' +
        'eines Verstoßes an die zuständigen Behörden vor.'      ,
    Aktualität: 'Aktualität'     ,
    AktualitätDesc: 'Wir werden diese Datenschutzerklärung ständig aktualisieren und bei Erforderlichkeit ändern und/oder\n' +
        'ergänzen.'
  },
  en: {
    title: 'Server Log Files',
    description: 'The provider of these pages may automatically collect and store information in so-called server log files,\n' +
        'which your browser automatically transmits to the server. This data may be used for statistical purposes.\n' +
        'Usually, when you visit this website, you send us the following information:\n',
    dataTypes: [
      'IP address',
      'Browser type and browser version',
      'Your operating system',
      'Referrer URL',
      'Hos tname of the accessing computer',
      'Time of server request'
    ],
    usage: 'This data cannot be attributed to specific persons; however, a subsequent attribution is theoretically\n' +
        'conceivable if your IP address is known, e.g. if you send us an email with further personal data via the same\n' +
        'IP address. However, we do not evaluate these server log files and do not use this data. Furthermore, we do\n' +
        'not store such data. We only process data that you have voluntarily and consciously transmitted to us and\n' +
        'that are only used for the purpose of exchanging communications. Other data is not of interest to\n' +
        'us. However, if we become aware of an illegal use of these server log files, we reserve the right to pass on\n' +
        'an infringement to the relevant authorities after checking it.\n'   ,
    Aktualität: 'Up-to-dateness'   ,
    AktualitätDesc: 'We will continually update this privacy policy and amend and/or supplement it as necessary'
  },
  ar: {
    title: 'ملفات سجل الخادم',
    description: 'يقوم مزود هذه الصفحات تلقائياً بجمع وتخزين المعلومات في ما يسمى ملفات سجل الخادم، والتي يرسلها متصفحك تلقائياً إلى الخادم.',
    dataTypes: [
      'عنوان IP',
      'نوع المتصفح وإصدار المتصفح',
      'نظام التشغيل الخاص بك',
      'عنوان URL المرجعي',
      'اسم المضيف للكمبيوتر الذي يصل',
      'وقت طلب الخادم'
    ],
    usage: 'ومع ذلك، لا يمكن إسناد هذه البيانات إلى أشخاص محددين، على الرغم من أن الإسناد اللاحق ممكن نظرياً إذا كان عنوان IP الخاص بك معروفاً، على سبيل المثال، إذا أرسلت لنا بريداً إلكترونياً يحتوي على بيانات شخصية إضافية من نفس عنوان IP.',
    Aktualität: 'الحداثة'      ,
    AktualitätDesc: 'نحن نقوم بتحديث هذه السياسة الخاصة بالخصوصية بشكل مستمر، وسنقوم بتعديلها و/أو إضافتها عند الحاجة.'
  }
};
